<template>
  <div class="w1092">
      <div class="myfixwrap">
        <p class="myfix"><span class="rdc">{{userData.memNick}}</span>{{$t('front.mypage.infoEdit')}}<br>{{$t('front.mypage.security')}}</p>
        <ul class="joinu mb10">
          <li>{{$t('front.common.memId')}}</li>
          <li>{{userData.memId}}</li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.mypage.passwordNew')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPass"></li>
        </ul>
        <span class="joinutext mb10">{{$t('front.signup.passwordPlaceholder')}}</span>
        <ul class="joinu">
          <li>{{$t('front.mypage.passwordOkNew')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPassCheck"></li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.common.cashOutPassNew')}}</li>
          <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric"  placeholder="" /></li>
        </ul>
        <span class="joinutext mb10">{{$t('front.signup.cashOutPassPlaceholder')}}</span>
        <ul class="joinu mb10">
          <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
          <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  placeholder="" v-model="model.cashOutPassCheck"></li>
        </ul>
        <div class="boardbtn"><a class="blsbtn right" @click="onUpdate">{{$t('front.board.edit')}}</a></div>
      </div>
      <div class="joinubottom">
        <ul class="">
          <li>{{$t('front.common.level')}}</li>
          <li>[Lv.{{userData.memLevel}}]</li>
        </ul>
        <!--ul class="joinu">
          <li>스포츠</li>
          <li>레벨 LV. {{userData.sportsLevel}}</li>
        </ul-->
        <ul class="">
          <li>{{$t('front.gnb.casino')}}</li>
          <li>[Lv.{{userData.casinoLevel}}]</li>
        </ul>
        <ul class="">
          <li>{{$t('front.gnb.slot')}}</li>
          <li>[Lv.{{userData.slotLevel}}]</li>
        </ul>
        <!--ul class="joinu">
          <li>미니게임</li>
          <li>레벨 LV. {{userData.mgLevel}}</li>
        </ul-->
      </div>
    </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}
.myfix {font-size: 16px;line-height: 1.5em;margin: 0 0 25px;color: #404654;}
.myfixwrap {border-radius: 3px;border: solid 1px #e8e8e8;background-color: #fff;padding: 22px 25px;}
.joinu {display: flex;gap: 10px;margin-bottom: 10px;}
.joinu li:first-child {width: 215px;color: #404654;justify-content: center;}
.joinu li {position: relative;display: flex;align-items: center;height: 38px;font-size: 14px;color: #404654;border: solid 1px #e8e8e8;background-color: #fafafa;width: 100%;color: #989898;}
.joinutext {font-size: 14px;color: #cacaca;padding: 0 0 0 190px;text-align: left;display: block;margin: 0 0 10px;}
.joinu input {padding: 0 0 0 12px;height: 38px;border: 0;width: 100%;font-size: 16px;background: none;color: #989898;box-sizing: border-box;background-color: #fff;}
.joinubottom {border-radius: 3px;border: solid 1px #e8e8e8;background-color: #fff;padding: 20px;display: flex;align-items: center;justify-content: center;gap: 100px;margin-top: 20px;}
.joinubottom ul {display: flex;gap: 15px;margin-bottom: 0;}
.joinubottom li:first-child {color: #404654;}
.joinubottom li {color: #c5a684;font-size: 14px;}
.boardbtn {margin-top: 200px !important;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
